import Layout, { siteTitle } from "../components/Layout";
import { getMediasNoMegastar, getMegastar } from "../utils/getMegastar";
import { useContext, useState } from "react";

import Container from "../components/Container";
import ContentTemplate from "../components/Layout/contentTemplate";
import { Context } from "../utils/store";
import Head from "next/head";
import fetchData from "../utils/fetch";
import fetchRequestLibrary from "../utils/fetchRequestLibrary";
import CustomHead from '../components/CustomHead';

const NUMBER_OF_BLOCKS = 3; // Number of blocks of articles
const SIZE_OF_BLOCKS = 5; // Number of articles per blocks

function Home({
    sorted_articles,
    thematiques,
    breakingNews,
    cities,
    ephemeralChannels,
    liveData,
    medias,
    auboisVousInforment,
}) {
    const [blockNb, setBlockNb] = useState(1);
    const [articles, setArticles] = useState(sorted_articles);
    const context = useContext(Context);
    async function seeMoreFunction() {
        let articles_fetched = await fetchData(
            process.env.SOURCE_API + "rest/2.0/medias/get.php",
            "POST",
            [
                { name: "limit", value: "15" },
                { name: "offset", value: blockNb * 15 },
            ],
            true
        );
        let size = SIZE_OF_BLOCKS;
        let sorted_articles = [];
        for (let i = 0; i < articles_fetched.items.length; i += size) {
            sorted_articles.push(articles_fetched.items.slice(i, i + size));
        }
        setArticles([...articles, ...sorted_articles]);
        setBlockNb(blockNb + 1);
    }

    return (
        <Context.Provider
            value={{
                ...context,
                medias: medias,
                thematiques: thematiques,
                cities: cities,
                breakingNews: breakingNews,
                liveData: liveData,
                ephemeralChannels: ephemeralChannels,
                activePage: "home",
                auboisVousInforment: auboisVousInforment,
            }}
        >
            <Layout>
                <Container>
                    <CustomHead title="A la une" article={articles[0][0]}/>

                    <ContentTemplate
                        type={"home"}
                        data={articles}
                        onClickFunction={seeMoreFunction}
                    />
                </Container>
            </Layout>
        </Context.Provider>
    );
}

export async function getServerSideProps(props) {

    const {
        thematiques,
        breakingNews,
        cities,
        ephemeralChannels,
        liveData,
        medias,
        auboisVousInforment
    } = await fetchRequestLibrary();

    const articles_fetched = medias;

    // Filter the medias that are megastar (value of video_megastar > 0)
    const megaStar = getMegastar(articles_fetched.items);

    // Filter the medias that are not megastar (value of video_megastar === 0)
    const otherMedias = getMediasNoMegastar(articles_fetched.items);

    let all = megaStar.concat(otherMedias);
    let size = SIZE_OF_BLOCKS;
    let sorted_articles = [];
    for (let i = 0; i < all.length; i += size) {
        sorted_articles.push(all.slice(i, i + size));
    }

    return {
        props: {
            sorted_articles,
            thematiques,
            breakingNews,
            cities,
            ephemeralChannels,
            liveData,
            medias,
            auboisVousInforment,
        },
    };
}

export default Home;
